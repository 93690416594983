
import moment from 'moment'
import { Component, Vue, Ref } from 'vue-property-decorator'
import ListView from '@/components/ListView.vue'
import StateSelect from '@/components/Reservation/StateSelect.vue'
import ShopAutocomplete from '@/components/ShopAutocomplete.vue'
import DateSelect from '@/components/DateSelect.vue'
import SelectNullBoolean from '@/components/SelectNullBoolean.vue'
import CSVExportBtn from '@/components/CSVExport.vue'
import CustomerAutocomplete from '@/components/CustomerAutocomplete.vue'

import { Reservation } from '../../types/reservation'
import { Dictionary } from 'vue-router/types/router'

@Component({
  components: {
    ListView,
    StateSelect,
    ShopAutocomplete,
    DateSelect,
    SelectNullBoolean,
    CSVExportBtn,
    CustomerAutocomplete,
  },
})
export default class ReservationListView extends Vue {
  headers = [
    { text: '予約ID', value: 'id', width: 80, fixed: 'left' },
    { text: '店コード', value: 'shopCode', width: 60 },
    { text: '店舗', value: 'shopName', width: 200 },
    { text: '来店日', value: 'date', width: 100 },
    { text: '来店時間', value: 'time', width: 80 },
    { text: '状態', value: 'stateText', width: 100 },
    { text: 'お客さま番号', value: 'customerCode', width: 100 },
    { text: 'アプリID', value: 'userEmail' },
    { text: '予約者氏名', value: 'reserver' },
    { text: '予約経路', value: 'reservingWayText', width: 150 },
    { text: '登録日時', value: 'createdAt', width: 150 },
    { text: '更新日時', value: 'updatedAt', width: 150 },
    { text: 'レビュー投稿日時', value: 'rating.created', width: 150 },
    { text: '顧客データ削除フラグ', value: 'customerDeleted' },
  ]
  filterDefinition = {
    search: { type: String, default: null },
    ordering: { type: String, default: '' },
    state: { type: Number, default: null },
    shop: { type: String, default: null },
    dateFrom: { type: String, default: null },
    dateTo: { type: String, default: null },
    rated: { type: Boolean, default: null },
    customer: { type: String, default: null },
    isRemoved: { type: Boolean, default: null },
  }
  filter: null | Dictionary<unknown> = null

  get totalCount() {
    return this.$api.reservations().count
  }

  get csvHeaders() {
    return CSVHeaders
  }
  get csvFetch() {
    return () => this.$api.reservations().list(this.listView.query)
  }

  get staffLoggedIn(): boolean {
    return this.$store.getters.staffLoggedIn
  }

  @Ref() readonly listView!: ListView

  getReserverName({ fullName, fullNameKana }: Reservation) {
    return `${fullNameKana} (${fullName})`
  }

  updateQuery() {
    if (this.filter !== null) this.listView.updateQuery(this.filter)
  }
}
const extractTime = (value: string | undefined) =>
  value ? moment(value).format('HH:mm') : ''
const extractDate = (value: string | undefined) =>
  value ? moment(value).format('YYYY-MM-DD') : ''
const CSVHeaders = [
  { key: 'id', label: '予約ID' },
  { key: 'isRemoved', label: '削除フラグ' },

  { key: 'shopCode', label: '店舗コード' },
  { key: 'shopName', label: '店舗名' },
  { key: 'date', label: '来店日' },
  { key: 'time', label: '来店時間' },

  { key: 'familyName', label: '姓' },
  { key: 'givenName', label: '名' },
  { key: 'familyNameKana', label: 'セイ' },
  { key: 'givenNameKana', label: 'メイ' },

  { key: 'customerUserEmail', label: 'アプリID' },
  { key: 'customerCode', label: 'お客様番号' },
  { key: 'customerPhoneNumber', label: '電話番号' },

  { key: 'reservingWayText', label: '予約経路' },
  { key: 'menuName', label: 'メニュー' },
  { key: 'stateText', label: '来店ステータス' },

  { key: 'sumVisited', label: '来店回数' },
  { key: 'attendedStaffCode', label: '予約対応スタッフコード' },
  { key: 'attendedStaffName', label: '予約対応スタッフ名' },
  {
    key: 'operationHistory.treatmentStaffCode',
    label: 'カウンセリングスタッフコード',
  },
  {
    key: 'operationHistory.treatmentStaffName',
    label: 'カウンセリングスタッフ名',
  },
  {
    key: 'operationHistory.dyeingStaffCode1',
    label: '塗布対応スタッフコード1',
  },
  {
    key: 'operationHistory.dyeingStaffName1',
    label: '塗布対応スタッフ名1',
  },
  {
    key: 'operationHistory.dyeingStaffCode2',
    label: '塗布対応スタッフコード2',
  },
  {
    key: 'operationHistory.dyeingStaffName2',
    label: '塗布対応スタッフ名2',
  },
  {
    key: 'operationHistory.shampooStaffCode',
    label: 'シャンプー対応スタッフコード',
  },
  {
    key: 'operationHistory.shampooStaffName',
    label: 'シャンプー対応スタッフ名',
  },
  {
    key: 'operationHistory.scalpProtectiveAgentText',
    label: '頭皮保護剤',
  },
  {
    key: 'treatmentName',
    label: 'トリートメント',
  },
  {
    key: (item: any) => extractDate(item.ratingPushNotified),
    label: 'レビューPush日',
  },
  {
    key: (item: any) => extractTime(item.ratingPushNotified),
    label: 'レビューPush時間',
  },
  {
    key: 'rating.point',
    label: 'レビュー点数',
  },
  {
    key: 'rating.freeComment',
    label: 'レビューコメント',
  },
  {
    key: (item: Reservation) => extractDate(item.rating?.created),
    label: 'レビュー投稿日',
  },
  {
    key: (item: Reservation) => extractTime(item.rating?.created),
    label: 'レビュー投稿時刻',
  },

  { key: (item: Reservation) => extractDate(item.createdAt), label: '作成日' },
  {
    key: (item: Reservation) => extractTime(item.createdAt),
    label: '作成時刻',
  },
  {
    key: (item: Reservation) => {
      const prevDate = item.previousReservedDate
      const prev45 = moment(item.date)
        .add(-45, 'days')
        .format('YYYY-MM-DD')
      if (prevDate && prev45 <= prevDate) {
        const total = item.sumVisited
        if (total >= 4) return 'RT'
        if (total === 2 || total === 3) return 'HT'
      }
    },
    label: 'RT/HT',
  },
  {
    key: (item: Reservation) => item.previousReservedDate,
    label: '前回来店日',
  },
  { key: (item: Reservation) => item.scalpCareName, label: '頭皮ケア' },

  { key: (item: Reservation) => extractDate(item.updatedAt), label: '更新日' },
  {
    key: (item: Reservation) => extractTime(item.updatedAt),
    label: '更新時刻',
  },
  {
    key: (item: Reservation) => item.receivesInfo,
    label: 'お知らせを受け取る',
  },
  {
    key: (item: Reservation) => item.customerDeleted,
    label: '顧客データ削除フラグ',
  },

  {
    key: 'operationHistory.trendColorText',
    label: 'トレンドカラー',
  },
  {
    key: 'hotpepperReservationNo',
    label: 'ホットペッパー予約番号',
  },
  {
    key: 'phoneNumber',
    label: '予約時電話番号',
  },
  {
    key: 'operationHistory.scalpProtectiveAgentTypeText',
    label: '頭皮保護剤の種類',
  },
]
